import "core-js/modules/es6.array.filter.js";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
import getCellSets from '../cellSets/getCellSets';
var getGroupSlidesBy = function getGroupSlidesBy(plotUuid) {
  return function (cellSets, componentConfig) {
    var _componentConfig$plot, _groupSlidesBy$filter;
    if (!cellSets.accessible) return [];
    var groupSlidesBy = (_componentConfig$plot = componentConfig[plotUuid]) === null || _componentConfig$plot === void 0 ? void 0 : _componentConfig$plot.config.groupSlidesBy;
    if (!(groupSlidesBy !== null && groupSlidesBy !== void 0 && groupSlidesBy.length)) return [];
    var properties = cellSets.properties;
    var existingSelectedTracks = (_groupSlidesBy$filter = groupSlidesBy === null || groupSlidesBy === void 0 ? void 0 : groupSlidesBy.filter(function (track) {
      return properties[track];
    })) !== null && _groupSlidesBy$filter !== void 0 ? _groupSlidesBy$filter : [];
    return existingSelectedTracks;
  };
};
export default createMemoizedSelector(getGroupSlidesBy, {
  inputSelectors: [function (state) {
    return getCellSets()(state.cellSets);
  }, function (state) {
    return state.componentConfig;
  }]
});